export const drawerWidth = 200;
export const baseUrl = "https://keellsm-prod-api.azurewebsites.net/api/keells-millionaire";

export const PathsUrls = {
  login: "/login",
  home: "/home",
};

export const Titles = {
  inventory: "Inventory",
};

export const imagePathData = [
  {
    id: 1,
    title: "Keells Green Bag Large",
  },
  {
    id: 2,
    title: "Keells Voucher 250",
  },
  {
    id: 3,
    title: "Dinner Voucher",
  },
  {
    id: 4,
    title: "Cash Prize Rs.7,000",
  },
  {
    id: 5,
    title: 'Chicken Submarine (6")',
  },
  {
    id: 6,
    title: "Chilled Pizza - Devilled Chicken",
  },
  {
    id: 7,
    title: "Krest Soya Meat Curry 90g",
  },
  {
    id: 11,
    title: "Keells Dhal 500G",
  },
  {
    id: 12,
    title: "Bubble Matic Detergent Powder 1kg",
  },
  {
    id: 13,
    title: "Total Bill Discount Of 10% (Max. Discount Rs.1000)",
  },
  {
    id: 14,
    title: "Stripes & Checks Gift Voucher",
  },
  {
    id: 15,
    title: "Keells Voucher Rs.1000",
  },
  {
    id: 16,
    title: "02 Donuts of Choice from Chocoloate, Cappuccino or Strawberry",
  },
  {
    id: 17,
    title: "Pastarina Spaghetti 500G",
  },
  {
    id: 21,
    title: "Keells Nexus Points 250 Points",
  },
  {
    id: 22,
    title: "Electricity Bill Wipe Off upto 10k",
  },
  {
    id: 23,
    title: "Nexus Bonus Points",
  },
  {
    id: 24,
    title: "Elephant House Twistee Apple 1L",
  },
  {
    id: 25,
    title: "Aroma Bliss Ceylon Gift Voucher",
  },
  {
    id: 26,
    title: "Fresh Coupons Veg & Fruits 15% (Max. Discount Rs.1500)",
  },
  {
    id: 27,
    title: "Raigam Devani Batha Bundy Full Chicken 80G",
  },
  {
    id: 31,
    title: "Alli Hopper Mixture White Rice 400G",
  },
  {
    id: 32,
    title: "Cash Prizes 15K",
  },
  {
    id: 33,
    title: "Elephant House Fit O Mixed Fruit 1L",
  },
  {
    id: 34,
    title: "Keells Whole Kernal Veg. Cooking Oil 500Ml",
  },
  {
    id: 35,
    title: "Roast Bread With Pol Sambol",
  },
  {
    id: 36,
    title: "Munchee Chocolate Cream Biscuit 100G",
  },
  {
    id: 37,
    title: "Keells Wheat Flour 1Kg",
  },
  {
    id: 41,
    title: "Fresh Coupons Meat 15% (Max. Discount Rs.1500)",
  },
  {
    id: 42,
    title: "Keells Voucher 500",
  },
  {
    id: 43,
    title: "Hotel Voucher",
  },
  {
    id: 44,
    title: "Grand Prize (Millionaire)",
  },
  {
    id: 45,
    title: "MDK String Hoppers White 700G",
  },
];

export const tableHeadingsList = [
  {
    label: "IMAGE",
    minWidth: 40,
  },
  {
    label: "NAME",
    minWidth: 50,
  },
  {
    label: "DESCRIPTION",
    minWidth: 60,
  },
  {
    label: "GIFT TIER",
    minWidth: 50,
  },
  {
    label: "ITEM CODE",
    minWidth: 50,
  },
  {
    label: "INITIAL QUANTITY",
    minWidth: 40,
  },
  {
    label: "REMAINING QUANTITY",
    minWidth: 40,
  },
  {
    label: "WEIGHT",
    minWidth: 40,
  },
  {
    label: "",
    minWidth: 50,
  },
  {
    label: "",
    minWidth: 50,
  },
];

export const dummyData = [
  {
    image: "/images/prizes/1.png",
    name: "keells",
    description: "its keels shop",
    itemCode: "kj14534",
    initialQuantity: 120,
    remainingQuantity: 80,
    weight: 2,
  },
];

export const GIFT_TIER = [
  {
    val: 1,
  },
  {
    val: 2,
  },
  {
    val: 3,
  },
];

import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { usePostSavePrizeMutation } from "../../../services/HomeApi";
import { GIFT_TIER, imagePathData } from "../../../utils/Data";
import FeedbackDialog from "../../../utils/FeedBackDialog";
import LoadingDialog from "../../../utils/LoadingDialog";
import ImagePickerDialog from "./ImagePickerDialog";

export default function AddItemPage({ closeAddItemFn }) {
  //#region HOOKS
  const [openImagePickerDialog, setOpenImagePickerDialog] = useState(false);
  const [imagePath, setImagePath] = useState("images/loading.jpg");
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    quantity: 0,
    weight: 0,
    itemId: "",
    imageId: "",
    gift_tier: GIFT_TIER[0].val,
  });
  const userToken = useSelector((state) => state.home.userToken);
  const [apiStateIndex, setApiStateIndex] = useState({
    index: 0,
    msg: "",
    callBack: null,
  });
  //#endregion

  //#region POST SAVE PRIZE API
  const [postSavePrizeApiRequest, { isLoading: isLoadingPostSavePriceApiRequestApi, error: postSavePriceApiRequestAPIError, isSuccess: editMainWeightRequestAPISuccess }] = usePostSavePrizeMutation();
  //#endregion

  //#region POST SAVE PRIZE API STATES
  let postSavePrizeContent;
  if (apiStateIndex.index === 1) {
    if (isLoadingPostSavePriceApiRequestApi) {
      postSavePrizeContent = <LoadingDialog />;
    } else if (postSavePriceApiRequestAPIError) {
      setApiStateIndex({
        index: 2,
        msg: postSavePriceApiRequestAPIError.data?.errorMessage ?? "Something Went Wrong",
        callBack: () => {
          setApiStateIndex({
            index: 0,
            msg: "",
          });
        },
      });
    }
  } else if (apiStateIndex.index === 2) {
    postSavePrizeContent = (
      <FeedbackDialog
        open={true}
        onClose={() => {
          apiStateIndex.callBack();
        }}
        data={{
          title: "Oops!",
          msg: apiStateIndex.msg,
        }}
      />
    );
  } else {
    postSavePrizeContent = null;
  }
  //#endregion

  //#region Functions
  const onImagePathChange = (id) => {
    setImagePath(`images/prizes/${id}.png`);
  };

  const onSelectImage = () => {
    console.log(imagePathData.filter((item) => item.id === parseInt(imagePath.split("images/prizes/")[1].split(".")[0])).map((item) => item.title)[0]);
    setFormData({
      ...formData,
      name: imagePathData.filter((item) => item.id === parseInt(imagePath.split("images/prizes/")[1].split(".")[0])).map((item) => item.title)[0],
      imageId: imagePath.split("images/prizes/")[1].split(".")[0],
    });
    setOpenImagePickerDialog(false);
  };

  const closeImagePicker = () => {
    setOpenImagePickerDialog(false);
    setImagePath(`images/loading.jpg`);
    setFormData({
      ...formData,
      name:'',
      imageId:''
    });
  };

  const onChangeFunction = (event) => {
    let eventName = event.target.name;
    let eventValue = event.target.value;
    if (eventName === "quantity" || eventName === "weight") {
      let value;
      if (eventValue) {
        value = parseInt(eventValue);
      } else {
        value = 0;
      }
      setFormData({
        ...formData,
        [eventName]: parseInt(value),
      });
    } else {
      setFormData({
        ...formData,
        [eventName]: eventValue,
      });
    }
  };

  const checkValidations = () => {
    if (!formData.imageId) {
      return {
        pass: false,
        msg: "PLEASE SELECT IMAGE",
      };
    }
    if (!formData.name) {
      return {
        pass: false,
        msg: " PLEASE ENTER NAME",
      };
    }
    if (!formData.description) {
      return {
        pass: false,
        msg: "PLEASE ENTER DESCRIPTION",
      };
    }
    if (formData.quantity <= 0) {
      return {
        pass: false,
        msg: "QUANTITY SHOULD NOT BE 0",
      };
    }
    if (formData.weight < 0 ) {
      return {
        pass: false,
        msg: "WEIGHT SHOULD NOT BE LESS THAN 0",
      };
    }
    if (!formData.itemId) {
      return {
        pass: false,
        msg: "PLEASE ENTER ITEM ID",
      };
    }

    return {
      pass: true,
    };
  };

  const saveFormData = () => {
    const result = checkValidations();
    if (result.pass) {
      console.log(formData);
      postSavePrizeApiRequest({ userToken: userToken, prizeData: formData });
      setApiStateIndex({
        index: 1,
        msg: "",
        callBack: null,
      });
    } else {
      setApiStateIndex({
        index: 2,
        msg: result.msg,
        callBack: () =>
          setApiStateIndex({
            index: 0,
            msg: "",
          }),
      });
    }
  };

  //#endregion

  //#region OPEN IMAGE PICKER DIALOG
  let openImagePickerDialogContent;
  if (openImagePickerDialog) {
    openImagePickerDialogContent = <ImagePickerDialog onImageClick={onImagePathChange} closeImagePicker={closeImagePicker} onSelectImage={onSelectImage} />;
  } else {
    openImagePickerDialogContent = null;
  }
  //#endregion

  useEffect(() => {
    if (editMainWeightRequestAPISuccess) {
      closeAddItemFn();
    }
  }, [editMainWeightRequestAPISuccess]);

  return (
    <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }}>
      <Typography variant="h6" sx={{ color: "rgba(98, 187, 71,255)", fontWeight: "bold" }}>
        Prize Data
      </Typography>
      <Box sx={{ height: "2vh" }} />
      <img src={imagePath} alt="loading img" height={60} width={60} />
      <Box sx={{ height: "2vh" }} />
      <Button
        size="small"
        onClick={() => {
          setOpenImagePickerDialog(true);
        }}
        style={{ backgroundColor: "transparent", color: "rgba(98, 187, 71,255)", padding: 5 }}
      >
        CHANGE
      </Button>
      <Box sx={{ height: "1vh" }} />
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center",width:'100%',paddingLeft:'100px',paddingRight:'100px' }}>
        <TextField
          sx={{
            "& label.Mui-focused": {
              color: "rgba(98, 187, 71,255)",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "rgba(98, 187, 71,255)",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "rgba(98, 187, 71,255)",
              },
              "&:hover fieldset": {
                borderColor: "rgba(98, 187, 71,255)",
              },
              "&.Mui-focused fieldset": {
                borderColor: "rgba(98, 187, 71,255)",
              },
            },
            marginBottom: "10px",
          }}
          size="small"
          fullWidth={true}
          id="name"
          label="Name"
          placeholder="Name"
          name="name"
          value={formData.name}
          type="text"
          onChange={onChangeFunction}
        />

        <TextField
          sx={{
            "& label.Mui-focused": {
              color: "rgba(98, 187, 71,255)",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "rgba(98, 187, 71,255)",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "rgba(98, 187, 71,255)",
              },
              "&:hover fieldset": {
                borderColor: "rgba(98, 187, 71,255)",
              },
              "&.Mui-focused fieldset": {
                borderColor: "rgba(98, 187, 71,255)",
              },
            },
            marginBottom: "10px",
          }}
          size="small"
          fullWidth={true}
          id="description"
          label="Description"
          placeholder="Description"
          name="description"
          value={formData.description}
          type="text"
          onChange={onChangeFunction}
        />

        <FormControl
          sx={{
            "& label.Mui-focused": {
              color: "rgba(98, 187, 71,255)",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "rgba(98, 187, 71,255)",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "rgba(98, 187, 71,255)",
              },
              "&:hover fieldset": {
                borderColor: "rgba(98, 187, 71,255)",
              },
              "&.Mui-focused fieldset": {
                borderColor: "rgba(98, 187, 71,255)",
              },
            },
            marginBottom: "10px",
          }}
          fullWidth={true}
          size="small"
        >
          <InputLabel id="giftTier">GiftTier</InputLabel>
          <Select fullWidth={true} label="GiftTier" id="GiftTier" name="gift_tier" value={formData.gift_tier} onChange={onChangeFunction}>
            {GIFT_TIER.map((item) => {
              return (
                <MenuItem key={item.val} value={item.val}>
                  {item.val}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <TextField
          sx={{
            "& label.Mui-focused": {
              color: "rgba(98, 187, 71,255)",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "rgba(98, 187, 71,255)",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "rgba(98, 187, 71,255)",
              },
              "&:hover fieldset": {
                borderColor: "rgba(98, 187, 71,255)",
              },
              "&.Mui-focused fieldset": {
                borderColor: "rgba(98, 187, 71,255)",
              },
            },
            marginBottom: "10px",
          }}
          size="small"
          fullWidth={true}
          id="quantity"
          label="Quantity"
          placeholder="Quantity"
          name="quantity"
          value={formData.quantity}
          type="number"
          inputProps={{
            min: "0",
            type: "number",
            step: "1",
            shrink: "true",
            pattern: "[0-9]*",
          }}
          onChange={onChangeFunction}
        />

        <TextField
          sx={{
            "& label.Mui-focused": {
              color: "rgba(98, 187, 71,255)",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "rgba(98, 187, 71,255)",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "rgba(98, 187, 71,255)",
              },
              "&:hover fieldset": {
                borderColor: "rgba(98, 187, 71,255)",
              },
              "&.Mui-focused fieldset": {
                borderColor: "rgba(98, 187, 71,255)",
              },
            },
            marginBottom: "10px",
          }}
          size="small"
          fullWidth={true}
          id="weight"
          label="Weight"
          placeholder="Weight"
          name="weight"
          value={formData.weight}
          type="number"
          inputProps={{
            min: "0",
            type: "number",
            step: "1",
            shrink: "true",
            pattern: "[0-9]*",
          }}
          onChange={onChangeFunction}
        />

        <TextField
          sx={{
            "& label.Mui-focused": {
              color: "rgba(98, 187, 71,255)",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "rgba(98, 187, 71,255)",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "rgba(98, 187, 71,255)",
              },
              "&:hover fieldset": {
                borderColor: "rgba(98, 187, 71,255)",
              },
              "&.Mui-focused fieldset": {
                borderColor: "rgba(98, 187, 71,255)",
              },
            },
            marginBottom: "10px",
          }}
          size="small"
          fullWidth={true}
          id="itemId"
          label="Item ID"
          placeholder="Item ID"
          name="itemId"
          value={formData.itemID}
          type="text"
          onChange={onChangeFunction}
        />

        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", }}>
          <Button fullWidth={true} size="small" onClick={saveFormData} style={{ backgroundColor: "rgba(98, 187, 71,255)", color: "white", padding: 5,marginRight:'50px' }}>
            SAVE
          </Button>

          <Button
            fullWidth={true}
            size="small"
            onClick={() => {
              closeAddItemFn();
            }}
            style={{ backgroundColor: "rgba(98, 187, 71,255)", color: "white", padding: 5 }}
          >
            CANCEL
          </Button>
        </Box>
      </Box>
      {openImagePickerDialogContent}
      {postSavePrizeContent}
    </Box>
  );
}

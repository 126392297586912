import { Box } from "@mui/material";
import React, { Fragment, useState } from "react";
import { drawerWidth } from "../../utils/Data";
import AddItem from "./AddItem";
import AddItemPage from "./AddItem/AddItemPage";
import MainWeight from "./MainWeight/MainWeight";
import TableComponent from "./TableComponent";

export default function Home() {
  //#region HOOKS
  const [openAddItem, setOpenAddItem] = useState(false);
  //#endregion

  //#region Functions
  const openAddItemFn = () => {
    setOpenAddItem(true);
  };
  const closeAddItemFn = () => {
    setOpenAddItem(false);
  };
  //#endregion

  let openAddItemContent;
  if (openAddItem) {
    openAddItemContent = <AddItemPage closeAddItemFn={closeAddItemFn}/>;
  } else {
    openAddItemContent = (
      <Fragment>
        <MainWeight />
        <br />
        <AddItem openAddItemFn={openAddItemFn} />
        <br/>
        <TableComponent />
      </Fragment>
    );
  }

  return (
    <Box sx={{ display: "flex", overflow: "hidden" }}>
      <Box
        sx={{
          display: { xs: "none", sm: "block" },
          width: "200px",
        }}
      ></Box>
      <Box
        sx={{
          width: { xs: "100%", sm: `calc(100% - ${drawerWidth}px)` },
          //backgroundColor:"red",
          marginTop: "36px",
          marginLeft: "15px",
          marginRight: "15px",
        }}
      >
        {openAddItemContent}
      </Box>
    </Box>
  );
}

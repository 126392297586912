import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../utils/Data";

const homeAPI = createApi({
  reducerPath: "homeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  endpoints: (builder) => ({
    getMainWeight: builder.mutation({
      query: (userToken) => ({
        url: `/prize/v1/settings/pool/rate`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-platform": "web",
          Authorization: `Bearer ${userToken}`,
        },
      }),
    }),
    editMainWeight: builder.mutation({
      query: ({ userToken, newMainWeight }) => ({
        url: `/prize/v1/settings/pool/rate?pp_rate=${newMainWeight}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-platform": "web",
          Authorization: `Bearer ${userToken}`,
        },
      }),
    }),
    postSavePrize: builder.mutation({
      query: ({ userToken, prizeData }) => ({
        url: `/prize/v1`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        body: {
          name: prizeData.name,
          description: prizeData.description,
          initial_value: prizeData.quantity,
          weight: prizeData.weight,
          image: prizeData.imageId,
          item_code: prizeData.itemId,
          gift_tier: prizeData.gift_tier,
        },
      }),
    }),
    getPrizeList: builder.mutation({
      query: (userToken) => ({
        url: `/prize/v1?page=0&size=50`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }),
    }),
    editPrize: builder.mutation({
      query: ({ userToken, newWeight, uuid, additionalQuantity, gift_tier }) => ({
        url: `/prize/v1?uuid=${uuid}&quantity=${additionalQuantity}&weight=${newWeight}&gift_tier=${gift_tier}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-platform": "web",
          Authorization: `Bearer ${userToken}`,
        },
      }),
    }),
    deletePrize: builder.mutation({
      query: ({ userToken, uuid }) => ({
        url: `prize/v1/${uuid}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-platform": "web",
          Authorization: `Bearer ${userToken}`,
        },
      }),
    }),
  }),
});

export const { useGetMainWeightMutation, useEditMainWeightMutation, usePostSavePrizeMutation, useGetPrizeListMutation, useEditPrizeMutation,useDeletePrizeMutation } = homeAPI;
export default homeAPI;

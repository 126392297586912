import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useEditMainWeightMutation } from "../../../services/HomeApi";
import FeedbackDialog from "../../../utils/FeedBackDialog";
import LoadingDialog from "../../../utils/LoadingDialog";

export default function MainWeightDialog({ onCloseFn, callGetMainWeight, currentMainWeight }) {
  //#region HOOKS
  const userToken = useSelector((state) => state.home.userToken);
  const [apiStateIndex, setApiStateIndex] = useState({
    index: 0,
    msg: "",
    callBack: null,
  });
  const [mainWeight, setMainWeight] = useState(currentMainWeight);

  //#endregion

  //#region EDIT MAIN WEIGHT API
  const [editMainWeightRequest, { isLoading: isLoadingEditMainWeightRequestApi, error: editMainWeightRequestAPIError, isSuccess: editMainWeightRequestAPISuccess }] = useEditMainWeightMutation();
  //#endregion

  //#region EDIT MAIN WEIGHT API STATES
  let editMainWeightContent;
  if (apiStateIndex.index === 1) {
    if (isLoadingEditMainWeightRequestApi) {
      editMainWeightContent = <LoadingDialog />;
    } else if (editMainWeightRequestAPIError) {
      setApiStateIndex({
        index: 2,
        msg: editMainWeightRequestAPIError.data?.errorMessage ?? "Something Went Wrong",
        callBack: () => {
          setApiStateIndex({
            index: 0,
            msg: "",
          });
        },
      });
    }
  } else if (apiStateIndex.index === 2) {
    editMainWeightContent = (
      <FeedbackDialog
        open={true}
        onClose={() => {
          apiStateIndex.callBack();
        }}
        data={{
          title: "Oops!",
          msg: apiStateIndex.msg,
        }}
      />
    );
  } else {
    editMainWeightContent = null;
  }
  //#endregion

  const onChangeFn = (event) => {
    setMainWeight(event.target.value);
  };

  const callEditMainWeightApi = () => {
    let index = -1;
    let msg = "";
    if (mainWeight) {
      if (mainWeight <= 1 && mainWeight >= 0) {
        index = 1;
        msg = "";
        editMainWeightRequest({ userToken: userToken, newMainWeight: mainWeight});
      } else {
        index = 2;
        msg = "ENTER WEIGHT BETWEEN 0 AND 1";
      }
    } else {
      index = 2;
      msg = "FIELD CAN'T BE EMPTY";
    }
    setApiStateIndex({
      index: index,
      msg: msg,
      callBack: () =>
        setApiStateIndex({
          index: 0,
          msg: "",
        }),
    });
  };

  useEffect(() => {
    if (editMainWeightRequestAPISuccess) {
      onCloseFn();
      callGetMainWeight();
    }
  }, [editMainWeightRequestAPISuccess]);

  return (
    <Dialog open={true} sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
      <DialogTitle sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Typography sx={{ color: "black", fontSize: "20" }}>Edit Main Weight (0-1)</Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ height: "1vh" }} />
        <TextField
          sx={{
            "& label.Mui-focused": {
              color: "rgba(98, 187, 71,255)",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "rgba(98, 187, 71,255)",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "rgba(98, 187, 71,255)",
              },
              "&:hover fieldset": {
                borderColor: "rgba(98, 187, 71,255)",
              },
              "&.Mui-focused fieldset": {
                borderColor: "rgba(98, 187, 71,255)",
              },
            },
          }}
          size="small"
          fullWidth={true}
          id="Main Weight"
          label="Main Weight"
          placeholder="Main Weight"
          name="mainWeight"
          type="number"
          value={mainWeight}
          onChange={onChangeFn}
        />
      </DialogContent>
      <DialogActions sx={{ padding: 0 }}>
        <Button onClick={callEditMainWeightApi}>OK</Button>
        <Button
          onClick={() => {
            onCloseFn();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
      {editMainWeightContent}
    </Dialog>
  );
}

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useEditPrizeMutation } from "../../../services/HomeApi";
import { GIFT_TIER } from "../../../utils/Data";
import FeedbackDialog from "../../../utils/FeedBackDialog";
import LoadingDialog from "../../../utils/LoadingDialog";

export default function EditPrizeDialog({ onCloseFn, item, callGetPrizeList }) {
  //#region HOOKS
  const userToken = useSelector((state) => state.home.userToken);
  const [apiStateIndex, setApiStateIndex] = useState({
    index: 0,
    msg: "",
    callBack: null,
  });
  const [formFieldData, setFormFieldData] = useState({
    additionalQuantity: 0,
    weight: item.weight,
    gift_tier: item.gift_tier,
  });
  //#endregion

  //#region ONCHANGE FN
  const onFieldChange = (event) => {
    setFormFieldData({
      ...formFieldData,
      [event.target.name]: event.target.value,
    });
  };
  //#endregion

  //#region EDIT PRIZE API
  const [editPrizeRequest, { isLoading: isLoadingEditPrizeRequestApi, error: editPrizeRequestAPIError, isSuccess: editPrizeRequestAPISuccess }] = useEditPrizeMutation();
  //#endregion

  //#region EDIT MAIN WEIGHT API STATES
  let editPrizeContent;
  if (apiStateIndex.index === 1) {
    if (isLoadingEditPrizeRequestApi) {
      editPrizeContent = <LoadingDialog />;
    } else if (editPrizeRequestAPIError) {
      setApiStateIndex({
        index: 2,
        msg: editPrizeRequestAPIError.data?.errorMessage ?? "Something Went Wrong",
        callBack: () => {
          setApiStateIndex({
            index: 0,
            msg: "",
          });
        },
      });
    }
  } else if (apiStateIndex.index === 2) {
    editPrizeContent = (
      <FeedbackDialog
        open={true}
        onClose={() => {
          apiStateIndex.callBack();
        }}
        data={{
          title: "Oops!",
          msg: apiStateIndex.msg,
        }}
      />
    );
  } else {
    editPrizeContent = null;
  }
  //#endregion

  const checkValidations = () => {
    if (formFieldData.additionalQuantity < 0) {
      return {
        pass: false,
        msg: "QUANTITY COULD NOT BE LESS THAN 0",
      };
    }
    if (formFieldData.weight < 0) {
      return {
        pass: false,
        msg: "WEIGHT COULD NOT BE LESS THAN 0",
      };
    }
    return {
      pass: true,
    };
  };

  const callEditPrizeApi = () => {
    const result = checkValidations();
    if (result.pass) {
      editPrizeRequest({ userToken: userToken, newWeight: parseInt(formFieldData.weight), uuid: item.uuid, additionalQuantity: parseInt(formFieldData.additionalQuantity), gift_tier: parseInt(formFieldData.gift_tier) });
      setApiStateIndex({
        index: 1,
        msg: "",
        callBack: null,
      });
    } else {
      setApiStateIndex({
        index: 2,
        msg: result.msg,
        callBack: () =>
          setApiStateIndex({
            index: 0,
            msg: "",
          }),
      });
    }
  };

  useEffect(() => {
    if (editPrizeRequestAPISuccess) {
      callGetPrizeList();
      onCloseFn();
    }
  }, [editPrizeRequestAPISuccess]);

  return (
    <Dialog open={true} sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
      <DialogTitle sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Typography sx={{ color: "black", fontSize: "20" }}>Edit Prize</Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ height: "1vh" }} />
        <TextField
          sx={{
            "& label.Mui-focused": {
              color: "rgba(98, 187, 71,255)",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "rgba(98, 187, 71,255)",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "rgba(98, 187, 71,255)",
              },
              "&:hover fieldset": {
                borderColor: "rgba(98, 187, 71,255)",
              },
              "&.Mui-focused fieldset": {
                borderColor: "rgba(98, 187, 71,255)",
              },
            },
          }}
          size="small"
          fullWidth={true}
          id="itemCode"
          label="Item Code"
          placeholder="Item Code"
          name="itemCode"
          value={item.item_code}
          type="text"
          InputProps={{
            readOnly: true,
          }}
        />
        <Box sx={{ height: "2vh" }} />
        <TextField
          sx={{
            "& label.Mui-focused": {
              color: "rgba(98, 187, 71,255)",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "rgba(98, 187, 71,255)",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "rgba(98, 187, 71,255)",
              },
              "&:hover fieldset": {
                borderColor: "rgba(98, 187, 71,255)",
              },
              "&.Mui-focused fieldset": {
                borderColor: "rgba(98, 187, 71,255)",
              },
            },
          }}
          size="small"
          fullWidth={true}
          id="additionalQuantity"
          label="Additional Quantity"
          placeholder="Additional Quantity"
          name="additionalQuantity"
          value={formFieldData.additionalQuantity}
          type="number"
          onChange={onFieldChange}
        />
        <Box sx={{ height: "2vh" }} />
        <TextField
          sx={{
            "& label.Mui-focused": {
              color: "rgba(98, 187, 71,255)",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "rgba(98, 187, 71,255)",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "rgba(98, 187, 71,255)",
              },
              "&:hover fieldset": {
                borderColor: "rgba(98, 187, 71,255)",
              },
              "&.Mui-focused fieldset": {
                borderColor: "rgba(98, 187, 71,255)",
              },
            },
          }}
          size="small"
          fullWidth={true}
          id="weight"
          label="Weight"
          placeholder="Weight"
          name="weight"
          value={formFieldData.weight}
          type="number"
          onChange={onFieldChange}
        />
        <Box sx={{ height: "2vh" }} />
        <FormControl fullWidth={true} size="small">
          <InputLabel id="giftTier">GiftTier</InputLabel>
          <Select fullWidth={true} label="GiftTier" id="GiftTier" name="gift_tier" value={formFieldData.gift_tier} onChange={onFieldChange}>
            {GIFT_TIER.map((item) => {
              return (
                <MenuItem key={item.val} value={item.val}>
                  {item.val}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ padding: 0 }}>
        <Button onClick={callEditPrizeApi}>OK</Button>
        <Button
          onClick={() => {
            onCloseFn();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
      {editPrizeContent}
    </Dialog>
  );
}

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../utils/Data";

export const loginApi = createApi({
  reducerPath: "loginApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  endpoints: (builder) => ({
    userLogin: builder.mutation({
      query: ({ email, password }) => ({
        url: `/user/v1/login?username=${email}&password=${password}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-platform": "web",
        },
      }),
    }),
  }),
});

export const { useUserLoginMutation } = loginApi;

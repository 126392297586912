import { Backdrop, Button, CircularProgress, Container, CssBaseline, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { PathsUrls} from "../../utils/Data";
import { useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useUserLoginMutation } from "../../services/LoginApi";
import FeedbackDialog from "../../utils/FeedBackDialog";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { homeActions } from "../../store/Store";
import packageData from "../../../package.json";
import LockIcon from "@mui/icons-material/Lock";
import PersonIcon from "@mui/icons-material/Person";

const Login = () => {
  //#region HOOKS & APIS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user, setUserDetails] = useState({
    userName: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [apiStateIndex, setApiStateIndex] = useState({
    index: 0,
    msg: "",
  });
  //#endregion

  //#region UTILS FNS
  const onFieldChange = (event) => {
    if (event.target.name === "userName") {
      setUserDetails({
        ...user,
        [event.target.name]: event.target.value,
      });
    } else if (event.target.name === "password") {
      setUserDetails({
        ...user,
        [event.target.name]: event.target.value,
      });
    }
  };

  const checkValidations = () => {
    if (user.userName === "") {
      return {
        pass: false,
        msg: "USERNAME CAN'T BE EMPTY",
      };
    } else if (user.password === "") {
      return {
        pass: false,
        msg: "PASSWORD CAN'T BE EMPTY",
      };
    }
    return {
      pass: true,
    };
  };

  const loginBtnClick = () => {
    let index = -1;
    let msg = "";

    const result = checkValidations();
    if (result.pass) {
      index = 1;
      msg = "";
      login({ email: user.userName, password: user.password });
    } else {
      msg = result.msg;
      index = 2;
    }

    setApiStateIndex({
      index: index,
      msg: msg,
    });
  };
  //#endregion

  //#region LOGIN API STATES

  const [login, { isLoading: isLoadingLoginApi, data: loginApiResponse, error: loginApiError }] = useUserLoginMutation();

  let loginApiContent;

  if (apiStateIndex.index === 1) {
    if (isLoadingLoginApi) {
      loginApiContent = (
        <Backdrop
          open={true}
          sx={{
            color: "rgb(75,73,172)",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <CircularProgress />
        </Backdrop>
      );
    } else if (loginApiError) {
      //console.log(loginApiError);

      setApiStateIndex({
        index: 2,
        msg: loginApiError?.data?.errorMessage ?? "Something Went Wrong",
      });
    }
  } else if (apiStateIndex.index === 2) {
    loginApiContent = (
      <FeedbackDialog
        open={true}
        onClose={() => {
          setApiStateIndex({
            index: 0,
            msg: "",
          });
        }}
        data={{
          title: "Oops...!",
          msg: apiStateIndex.msg,
        }}
      />
    );
  }

  useEffect(() => {
    if (loginApiResponse) {
      //console.log(loginApiResponse.data);
      dispatch(homeActions.setUserToken(loginApiResponse.data));
      navigate(PathsUrls.home, { replace: "true" });
    }
  }, [loginApiResponse]);
  //#endregion

  return (
    <Fragment>
      <CssBaseline />
      <div
        style={{
          //backgroundImage: "url(" + bgImage + ")",
          backgroundColor: "rgba(98, 187, 71,255)",
          height: "100vh",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          position: "relative",
          width: "100vw",
        }}
      >
        <Container
          maxWidth="xs"
          style={{
            textAlign: "center",
            backgroundColor: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "30px",
            opacity: 0.9,
            borderRadius: 5,
          }}
        >
          <Grid justifyContent="center" spacing={3} container direction={"column"}>
            <Grid item xs={12} md={6} lg={6}>
              <Typography
                style={{
                  color: "rgb(75,73,172)",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                KEELLS MILLIONAIRE DASHBOARD - {packageData.version + " v"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                sx={{
                  "& label.Mui-focused": {
                    color: "rgb(75,73,172)",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "rgb(75,73,172)",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "rgb(75,73,172)",
                    },
                    "&:hover fieldset": {
                      borderColor: "rgb(75,73,172)",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "rgb(75,73,172)",
                    },
                  },
                }}
                size="small"
                fullWidth={true}
                id="userName"
                label="UserName"
                //placeholder="UserName"
                name="userName"
                type="text"
                value={user.userName}
                onChange={onFieldChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton aria-label="toggle password visibility">
                        <PersonIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                sx={{
                  "& label.Mui-focused": {
                    color: "rgb(75,73,172)",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "rgb(75,73,172)",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "rgb(75,73,172)",
                    },
                    "&:hover fieldset": {
                      borderColor: "rgb(75,73,172)",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "rgb(75,73,172)",
                    },
                  },
                }}
                size="small"
                fullWidth={true}
                id="password"
                label="Password"
                //placeholder="Password"
                name="password"
                type={showPassword ? "text" : "password"}
                value={user.password}
                onChange={onFieldChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton aria-label="toggle password visibility">
                        <LockIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Button onClick={loginBtnClick} variant="h6" style={{ backgroundColor: "rgba(98, 187, 71,255)", color: "white" }}>
                Login
              </Button>
            </Grid>
          </Grid>
          {loginApiContent}
        </Container>
      </div>
    </Fragment>
  );
};

export default Login;

import { Box, Button } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";

export default function AddItemButton({ openAddItemFn }) {
  return (
    <Box sx={{ display: "flex", alignItems: "end", justifyContent: "end", height: "10vh", }}>
      <Button
        onClick={() => {
          openAddItemFn();
        }}
        style={{ backgroundColor: "rgba(98, 187, 71,255)", color: "white", padding: 5 }}
        startIcon={<AddIcon sx={{ color: "white" }} />}
      >
        Add Item
      </Button>
    </Box>
  );
}

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDeletePrizeMutation, useGetPrizeListMutation } from "../../../services/HomeApi";
import { GIFT_TIER, PathsUrls, tableHeadingsList } from "../../../utils/Data";
import FeedbackDialog from "../../../utils/FeedBackDialog";
import LoadingDialog from "../../../utils/LoadingDialog";
import EditPrizeDialog from "../AddItem/EditPrizeDialog";

export default function TableComponent() {
  //#region API && HOOKS
  const navigate = useNavigate();
  const userToken = useSelector((state) => state.home.userToken);
  const [openEditPrizeDialog, setOpenEditPrizeDialog] = useState(false);
  const itemRef = useRef({
    uuid: "",
    item_code: "",
    gift_tier: GIFT_TIER[0].val,
    weight:0
  });
  const [apiStateIndex, setApiStateIndex] = useState({
    index: 0,
    msg: "",
    callBack: null,
  });
  //#endregion

  //#region GET PRIZE LIST API
  const [getPrizeList, { isLoading: isLoadingGetPrizeListRequestApi, error: getPrizeListRequestAPIError, data: getPrizeListRequestAPIData }] = useGetPrizeListMutation();
  //#endregion

  //#region DELETE PRIZE LIST API
  const [deletePrizeRequest, { isLoading: isLoadingDeletePrizeListRequestApi, error: deletePrizeListRequestAPIError, isSuccess: isSuccessDeletePrize }] = useDeletePrizeMutation();
  //#endregion

  //#region FN
  const callGetPrizeListFn = () => {
    getPrizeList(userToken);
  };
  //#endregion

  useEffect(() => {
    callGetPrizeListFn();
  }, []);

  //#region DELETE PRIZE LIST API STATES
  let deletePrizeListContent;
  if (apiStateIndex.index === 1) {
    if (isLoadingDeletePrizeListRequestApi) {
      deletePrizeListContent = <LoadingDialog />;
    } else if (deletePrizeListRequestAPIError) {
      setApiStateIndex({
        index: 2,
        msg: deletePrizeListRequestAPIError.data?.errorMessage ?? "Something Went Wrong",
        callBack: () => {
          setApiStateIndex({
            index: 0,
            msg: "",
          });
        },
      });
    } else {
      deletePrizeListContent = (
        <FeedbackDialog
          open={true}
          onClose={() => {
            callGetPrizeListFn();
            setApiStateIndex({
              index: 0,
              msg: "",
            });
          }}
          data={{
            title: "Success!",
            msg: "Successfully Deleted!",
          }}
        />
      );
    }
  } else if (apiStateIndex.index === 2) {
    deletePrizeListContent = (
      <FeedbackDialog
        open={true}
        onClose={() => {
          apiStateIndex.callBack();
        }}
        data={{
          title: "Oops!",
          msg: apiStateIndex.msg,
        }}
      />
    );
  } else {
    deletePrizeListContent = null;
  }
  //#endregion

  //#region OPEN IMAGE PICKER DIALOG
  const closeOpenEditPrizeDialogBox = () => {
    setOpenEditPrizeDialog(false);
  };
  let openEditPrizeDialogContent;
  if (openEditPrizeDialog) {
    openEditPrizeDialogContent = <EditPrizeDialog onCloseFn={closeOpenEditPrizeDialogBox} item={itemRef.current} callGetPrizeList={callGetPrizeListFn} />;
  } else {
    openEditPrizeDialogContent = null;
  }
  //#endregion

  //#region GET PRIZE LIST API STATES
  let getPrizeListContent;
  if (isLoadingGetPrizeListRequestApi) {
    getPrizeListContent = <LoadingDialog />;
  } else if (getPrizeListRequestAPIError) {
    getPrizeListContent = (
      <FeedbackDialog
        open={true}
        onClose={() => {
          navigate(PathsUrls.login, { replace: "true" });
        }}
        data={{
          title: "Oops!",
          msg: getPrizeListRequestAPIError.data?.errorMessage ?? "Something Went Wrong",
        }}
      />
    );
  } else if (getPrizeListRequestAPIData) {
    getPrizeListContent = getPrizeListRequestAPIData.data.dataRows.map((row, index) => {
      return (
        <TableRow
          tabIndex={-1}
          key={index}
          sx={{
            height: "8vh",
          }}
        >
          <TableCell style={{ borderBottom: "1px solid black" }} align="left">
            <img src={`images/prizes/${row.image}.png`} alt="Prize Logo" style={{ height: 50, padding: 5, borderRadius: 10 }} />
          </TableCell>
          <TableCell style={{ borderBottom: "1px solid black" }} align="left">
            {row.name}
          </TableCell>
          <TableCell style={{ borderBottom: "1px solid black" }} align="left">
            {row.description}
          </TableCell>
          <TableCell style={{ borderBottom: "1px solid black" }} align="left">
            {row.gift_tier}
          </TableCell>
          <TableCell style={{ borderBottom: "1px solid black" }} align="left">
            {row.item_code}
          </TableCell>
          <TableCell style={{ borderBottom: "1px solid black" }} align="left">
            {row.initial_value}
          </TableCell>
          <TableCell style={{ borderBottom: "1px solid black" }} align="left">
            {row.remaining_value}
          </TableCell>
          <TableCell style={{ borderBottom: "1px solid black" }} align="left">
            {row.weight}
          </TableCell>
          <TableCell style={{ borderBottom: "1px solid black" }} align="left">
            <Box
              onClick={() => {
                itemRef.current = {
                  uuid: row.uuid,
                  item_code: row.item_code,
                  gift_tier: row.gift_tier,
                  weight: row.weight,
                };
                setOpenEditPrizeDialog(true);
              }}
              style={{ padding: "10", backgroundColor: "rgba(98, 187, 71,255)", borderRadius: "50%", width: 35, height: 35, alignItems: "center", display: "flex", justifyContent: "center", boxSizing: "border-box" }}
            >
              <Box
                style={{
                  backgroundImage: `url(images/edit.png)`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  borderRadius: "50%",
                  zIndex: "1",
                  width: 25,
                  height: 25,
                  boxSizing: "border-box",
                  backgroundColor: "rgba(98, 187, 71,255)",
                  cursor: "pointer",
                }}
              ></Box>
            </Box>
          </TableCell>
          <TableCell style={{ borderBottom: "1px solid black" }} align="left">
            <Box
              onClick={() => {
                deletePrizeRequest({ userToken: userToken, uuid: row.uuid });
                setApiStateIndex({
                  index: 1,
                  msg: "",
                  callBack: () =>
                    setApiStateIndex({
                      index: 0,
                      msg: "",
                    }),
                });
              }}
              style={{ padding: "10", backgroundColor: "rgba(98, 187, 71,255)", borderRadius: "50%", width: 35, height: 35, alignItems: "center", display: "flex", justifyContent: "center", boxSizing: "border-box" }}
            >
              <Box
                style={{
                  backgroundImage: `url(images/delete.png)`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  borderRadius: "50%",
                  zIndex: "1",
                  width: 25,
                  height: 25,
                  boxSizing: "border-box",
                  backgroundColor: "rgba(98, 187, 71,255)",
                  cursor: "pointer",
                }}
              ></Box>
            </Box>
          </TableCell>
        </TableRow>
      );
    });
  }
  //#endregion

  return (
    <TableContainer sx={{ maxHeight: '62vh' }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {tableHeadingsList.map((item, i) => (
              <TableCell
                style={{
                  marginRightRight: "0px",
                  minWidth: item.minWidth,
                  paddingRight: "0px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  backgroundColor: "grey",
                }}
                align="left"
                key={i}
              >
                {item.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{getPrizeListContent}</TableBody>
      </Table>
      {openEditPrizeDialogContent}
      {deletePrizeListContent}
    </TableContainer>
  );
}

import { Button, Dialog, DialogActions, ImageList, ImageListItem } from "@mui/material";
import React from "react";
import { imagePathData } from "../../../utils/Data";

export default function ImagePickerDialog({ onImageClick, closeImagePicker, onSelectImage }) {
  return (
    <Dialog open={true}>
      <ImageList sx={{ width: 300, height: 150 }} cols={3} rowHeight={80}>
        {imagePathData.map((item) => (
          <ImageListItem key={item.id}>
            <img
              onClick={() => {
                onImageClick(item.id);
              }}
              src={`images/prizes/${item.id}.png`}
              alt={item.title}
              width={80}
              height={80}
              style={{ cursor: "pointer" }}
            />
          </ImageListItem>
        ))}
      </ImageList>
      <DialogActions sx={{ padding: 0 }}>
        <Button onClick={()=>{
          onSelectImage();
        }}>OK</Button>
        <Button
          onClick={() => {
            closeImagePicker();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import LogoutIcon from "@mui/icons-material/Logout";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import InventoryIcon from "@mui/icons-material/Inventory";
import { drawerWidth, PathsUrls, Titles } from "../../utils/Data";
import { useDispatch } from "react-redux";
import { homeActions } from "../../store/Store";

const itemList = [
  {
    text: Titles.inventory,
    icon: <InventoryIcon />,
    location: PathsUrls.home,
  },
];

const CustomAppBar = () => {
  //#region API & HOOKS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const currentLocation = useLocation();
  //#endregion

  //#region UTIL FNS
  const logout = () => {
    dispatch(homeActions.setUserToken(""));
    navigate(PathsUrls.login, { replace: "true" });
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onDrawerItemSelect = (index) => {
    setSelectedItemIndex(index);
    navigate(itemList[index].location, { replace: "true" });
  };

  //#endregion
  useEffect(() => {
    const currentIndex = itemList.findIndex((ele) => ele.location === currentLocation.pathname);
    if (currentIndex >= 0) {
      onDrawerItemSelect(currentIndex);
    }
  }, []);
  //#region DRAWER CONTENT
  const drawerContent = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {itemList.map((item, index) => {
          const { text, icon } = item;
          return (
            <ListItem
              button
              key={index}
              onClick={() => {
                onDrawerItemSelect(index);
              }}
              style={{
                backgroundColor: selectedItemIndex === index ? "rgba(98, 187, 71,255)" : "rgb(255,255,255)",
                color: selectedItemIndex === index ? "white" : "black",
              }}
            >
              <ListItemIcon sx={{ color: selectedItemIndex === index ? "white" : "black" }}>{icon}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
  //#endregion

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          //height : "7vh"
        }}
        elevation={0}
        style={{ background: "rgb(0,0,0)", overflow: "hidden" }}
      >
        <Toolbar>
          <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: "none" } }}>
            <MenuIcon />
          </IconButton>
          <img src={"images/keells.png"} alt="Keells Logo" style={{ height:50,padding:5,borderRadius:10}} />
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            KEELLS MILLIONAIRE DASHBOARD
          </Typography>
          <Button onClick={logout} startIcon={<LogoutIcon style={{ color: "white" }} />} color="inherit" variant="h6" style={{ color: "white", backgroundColor: "rgba(98, 187, 71,255)" }}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          height: "7vh",
          flexShrink: { sm: 0 },
        }}
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawerContent}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawerContent}
        </Drawer>
      </Box>
    </Box>
  );
};

export default CustomAppBar;

import { Box, Grid, Input, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetMainWeightMutation } from "../../../services/HomeApi";
import { PathsUrls } from "../../../utils/Data";
import FeedbackDialog from "../../../utils/FeedBackDialog";
import LoadingDialog from "../../../utils/LoadingDialog";
import MainWeightDialog from "./MainWeightDialog";

export default function MainWeight() {
  //#region HOOKS
  const navigate = useNavigate();
  const userToken = useSelector((state) => state.home.userToken);
  const [openMainWeightDialog, setOpenMainWeightDialog] = useState(false);
  const currentMainWeight = useRef("");
  //#endregion

  //#region GET MAIN WEIGHT API
  const [getMainWeight, { isLoading: isLoadingGetMainWeightRequestApi, error: getMainWeightRequestAPIError, data: getMainWeightRequestAPIData }] = useGetMainWeightMutation();
  //#endregion

  //#region FN
  const getMainWeightFn = () => {
    getMainWeight(userToken);
  };
  //#endregion

  useEffect(() => {
    getMainWeightFn();
  }, []);

  //#region GET MAIN WEIGHT API STATES
  let getMainWeightContent;
  if (isLoadingGetMainWeightRequestApi) {
    getMainWeightContent = <LoadingDialog />;
  } else if (getMainWeightRequestAPIError) {
    getMainWeightContent = (
      <FeedbackDialog
        open={true}
        onClose={() => {
          navigate(PathsUrls.login, { replace: "true" });
        }}
        data={{
          title: "Oops!",
          msg: getMainWeightRequestAPIError.data?.errorMessage ?? "Something Went Wrong",
        }}
      />
    );
  } else {
    //console.log(getMainWeightRequestAPIData.data.infoValue);
    currentMainWeight.current = getMainWeightRequestAPIData?.data?.infoValue;
    getMainWeightContent = <Input style={{ width: 25 }} type="text" inputProps={{ readOnly: true }} value={getMainWeightRequestAPIData?.data?.infoValue} />;
  }

  //#endregion

  //#region MAIN WEIGHT DIALOG
  const closeMainWeightDialog = () => {
    setOpenMainWeightDialog(false);
  };

  let mainWeightDialogContent = null;
  if (openMainWeightDialog) {
    mainWeightDialogContent = <MainWeightDialog onCloseFn={closeMainWeightDialog} callGetMainWeight={getMainWeightFn} currentMainWeight={currentMainWeight.current} />;
  } else {
    mainWeightDialogContent = null;
  }
  //#endregion

  return (
    <Box sx={{height:'10vh'}}>
      <Grid container direction="row" justifyContent="center" spacing={3} display="flex" alignItems={"center"}>
        <Grid item>
          <Typography sx={{ color: "black", fontSize: "20" }}>Main Weight</Typography>
        </Grid>
        <Grid item>{getMainWeightContent}</Grid>
        <Grid item>
          <Box
            onClick={() => {
              setOpenMainWeightDialog(true);
            }}
            style={{ padding: "10", backgroundColor: "rgba(98, 187, 71,255)", borderRadius: "50%", width: 35, height: 35, alignItems: "center", display: "flex", justifyContent: "center", boxSizing: "border-box" }}
          >
            <Box
              style={{
                backgroundImage: `url(images/edit.png)`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                borderRadius: "50%",
                zIndex: "1",
                width: 25,
                height: 25,
                boxSizing: "border-box",
                backgroundColor: "rgba(98, 187, 71,255)",
                cursor: "pointer",
              }}
            ></Box>
          </Box>
        </Grid>
        {mainWeightDialogContent}
      </Grid>
    </Box>
  );
}
